<template>
  <div class="email_box">
    <div class="e_top_img">
      <img src="../../assets/img/email_img.png" alt="" />
    </div>
    <div class="e_main">
      <div class="e_title1">您好！</div>
      <div class="e_title2">
        为了保证面试顺利，请您添加面试时间
      </div>
      <div class="e_title3">已添加时间</div>
      <div class="e_radio" v-for="(item,index) in list" :key="index">
        <div class="time_box">{{ time_format(item.start) }} ~ {{ time_format(item.end) }}</div>
      </div>

      <div class="e_title3" style="margin-top:40px;">添加面试时间</div>
      <div class="new_order_row">
        <div class="row_form">
          <el-form :inline="true" label-position="right" label-width="auto">
            <div
              class="form_margin_btm"
              v-for="(item, index) in time_list"
              :key="index"
            >
              <el-form-item label="面试时间：">
                <el-date-picker
                  v-model="item.start"
                  type="datetime"
                  :picker-options="pickerOptions"
                  value-format="timestamp"
                  placeholder="请选择时间"
                >
                </el-date-picker>
                <span class="parting_line"> — </span>
                <el-date-picker
                  v-model="item.end"
                  type="datetime"
                  :picker-options="pickerOptions"
                  value-format="timestamp"
                  placeholder="请选择时间"
                >
                </el-date-picker>
                <span class="parting_line"> &nbsp; </span>
                <el-checkbox v-model="item.skip_time">12:00-13:00可用</el-checkbox>
              </el-form-item>
            </div>
            <div class="form_margin_btm">
              <el-form-item label=" ">
                <el-button
                  plain
                  class="order_btn"
                  size="small"
                  icon="el-icon-plus"
                  @click="add_row2_time"
                  >添加时间</el-button
                >
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>

      <div>
        <el-button
          type="primary"
          class="e_submit_btm"
          @click="is_attend_confirm"
          >提交</el-button
        >
      </div>
    </div>

  </div>
</template>

<script>
import {getWorkOrderTime,addInterviewTime} from '@/api/api';
export default {
  data() {
    return {
      order_id:"",
      interviewer_id:"",
      list:[],
      pickerOptions:{
        disabledDate(time){
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },

      time_list: [
        {
          start: "",
          end: "",
          skip_time: 0, //是否跳过12点到13点
        },
      ],

    };
  },
  mounted() {
    // /interviewer_add_time_email?order_id=20231209hr_253SE5p&interviewer_id=657426665164291acb0b3421
    // ?order_id=xxx&interviewer_id=xxx

    if (window.location.href.indexOf("?") !== -1) {
      const url = new URL(window.location.href);
      const order_id = url.searchParams.get("order_id");
      const interviewer_id = url.searchParams.get("interviewer_id");
      this.order_id = order_id;
      this.interviewer_id = interviewer_id;
    }
    this.order_id && this.get_list();

  },
  methods: {
    async get_list(){
      const res = await getWorkOrderTime({order_id:this.order_id});
      if(res.data.code!=200){
          return this.msg("error",res.data.msg);
      }
      this.list = res.data.data.list;
      // console.log(this.list);
    },
    async is_attend_confirm() {
      if(!this.time_list[0].start||!this.time_list[0].start){
        return this.msg("error", "请添加时间");
      }
      const time_list=[];
      for (const item of this.time_list) {
        if(item.start&&item.end&&item.skip_time!==''){
          time_list.push({
            start:item.start,
            end:item.end,
            skip_time:item.skip_time?1:0,
          })
        }
      }
      
      const res = await addInterviewTime({
        order_id: this.order_id,
        interviewer_id: this.interviewer_id,
        interval: time_list,
      });
      if(res.data.code!=200){
        return this.msg("error",res.data.msg);
      }
      this.msg("success", "提交成功");
      this.time_list= [
        {
          start: "",
          end: "",
          skip_time: 0, //是否跳过12点到13点
        },
      ];


    },
    add_row2_time() {
      let leng_th = this.time_list.length - 1;
      if (leng_th < 0) return;
      if (
        !this.time_list[leng_th].start ||
        !this.time_list[leng_th].end
      ) {
        return false;
      }
      this.time_list.push({
        start: "",
        end: "",
        skip_time: 0, //是否跳过12点到13点
      });
      
    },
  },
};
</script>

<style scoped>
.email_box{
  height: 100vh;
}
/* *{
    margin: 0;
    padding: 0;
    font-family: "PingFang SC","Helvetica Neue",Helvetica,"Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
} */
.e_top_img {
  /* height: 200px;
  background-color: pink; */
}
.e_top_img img {
  width: 100%;
}
.e_main {
  width: 75%;
  max-width: 800px;
  margin: 30px auto;
}
.e_title1 {
  color: #333;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}
.e_title2 {
  color: #a9a9a9;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 40px;
}
.e_title3 {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.e_select_box {
  border-radius: 6px;
  background: #f4f5fa;
  box-sizing: border-box;
  padding: 35px;
  margin-bottom: 40px;
}
.is_c_box_1 {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
}
.e_select_box .el-checkbox__input.is-checked + .el-checkbox__label,
.e_select_box .el-checkbox__label {
  color: #333;
  font-size: 16px;
}
.e_radio {
  margin-top: 15px;
}
.e_radio .el-radio__input.is-checked + .el-radio__label {
  color: #333;
}
.time_center {
  box-sizing: border-box;
  padding: 0 15px;
}
.is_c_time {
  margin-top: 15px;
}
.time_btn_txt {
  margin-top: 10px;
  color: red;
  font-size: 12px;
  font-weight: 400;
}
.e_radio .el-radio {
  color: #a9a9a9;
}
.c2_inp {
  max-width: 400px;
}
.e_submit_btm,
.e_submit_btm:hover,
.e_submit_btm:focus,
.e_submit_btm:active,
.e_submit_btm:valid {
  min-width: 440px;
  height: 50px;
  background: #0a76e2;
  border-color: #0a76e2;
  color: #fff;
}

.is_c_time >>> .el-input.is-disabled .el-input__inner {
    background-color: #fff;
    border-color: #DCDFE6;
    color: #606266;
    cursor: not-allowed;
}
.time_box{
  margin-bottom: 10px;
}

</style>
